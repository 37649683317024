<template>
  <div class="layout_bottom">
    <div class="inner flex jc-sb ai-c">
      <div class="center_title">
        <img src="@/assets/images/loanImg/logo_2.png" alt="">
        <span class="title">Tiger Credit</span>
      </div>
      <div class="center_1">
        <a href="https://tigercreditapp.com/terms_of_service">Terms & Conditions</a>
      </div>
      <div class="center_1">
        <a href="https://tigercreditapp.com/privacy_policy">Privacy Policy</a>
      </div>
      <div class="item_download" @click="downloadApp()">
        <img src="@/assets/images/Google.png" alt="">
        <p>Click and get cash now!</p>
      </div>
    </div>
    <div class="inner inner2">
      <span class="title">Copyright © Tiger Credit All rights reserved</span>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    downloadApp() {
      window.location.href = 'https://play.google.com/store/apps/details?id=easy.credit.app.fast'
    }
  }
}
</script>
<style lang="scss" scoped>
.layout_bottom{
  display: flex;
  // align-items: center;
  justify-content: center;
  color: #fff;
  flex-direction: column;
  img{
    margin-right: 10px;
    width: 40px;
    display: inline-block;
  }
  .inner{
    padding:50px calc((100% - 1100px) / 2);
    background: #060300;
  }
  .center_title{
    display: flex;
    align-items: center;
    // margin-bottom: 40px;
  }
  .item_download {
    text-align: center;
    font-size: 12px;
    img{
      display: block;
      width: 150px;
      height: 45px;
      margin: 0 0 15px 0;
    }
  }
  .center_1{
      display: flex;
      align-items: center;
      font-size: 12px;
      a{
        color: white;
        text-decoration: underline;
      }
    }
  .inner2{
    padding:20px calc((100% - 1100px) / 2);
    background: #4D4D4D;
    text-align: center;
  }
}
@media only screen and (max-width: 720px){
  .layout_bottom{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #fff;
    padding: 20px 0px 0px;
    .inner{
      width: 100%;
      flex-direction: column;
      padding: 50px 20px;
      align-items: flex-start;
    }
    .center_title{
      margin-bottom: 20px;
    }
    .center_1{
        display: flex;
        align-items: center;
        margin-bottom: 15px;
      }
    .inner2{
      padding: 20px ;
    }
  }
}
</style>
